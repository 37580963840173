import i18n from '@/i18n/index'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.common.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.common.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'site_id',
      name: 'select',
      options: [],
      label: i18n.t('form.basicMessage.scenicSpot'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        key: 'id',
        value: 'id',
        filterable: true
      }
    }, {
      span: 8,
      prop: 'redeem_code',
      name: 'input',
      label: i18n.t('form.attractions.redemptionCode'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'lon',
      name: 'input',
      type: 'number',
      label: i18n.t('form.common.longitude'),
      placeholder: i18n.t('form.common.lontip'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'lat',
      name: 'input',
      type: 'number',
      label: i18n.t('form.common.latitude'),
      placeholder: i18n.t('form.common.lattip'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'description',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.descriptionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'description_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.descriptionEn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'introduce',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.introductionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'introduce_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.introductionEn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'rating',
      name: 'input',
      type: 'number',
      label: i18n.t('form.common.rating'),
      placeholder: i18n.t('0.0 - 5.0'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'address',
      name: 'input',
      label: i18n.t('form.common.address'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'website',
      name: 'input',
      label: i18n.t('form.attractions.website'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'phone_number',
      name: 'input',
      label: i18n.t('form.attractions.contactNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'email',
      name: 'input',
      label: i18n.t('form.common.mail'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'operation_time',
      name: 'input',
      label: i18n.t('form.common.operationHoursCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'operation_time_en',
      name: 'input',
      label: i18n.t('form.common.operationHoursEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'tips',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.remindersCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'tips_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.remindersEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'traffic_strategy',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.transportationGuideCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'traffic_strategy_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.transportationGuideEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }]
  },
  {
    title: i18n.t('form.common.image'),
    name: 1,
    createDateItem: [{
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      value: [],
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 100,
        imageName: 'image_url'
      }
    }]
  }]
}
